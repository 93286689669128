@tailwind base;
@tailwind components;
@tailwind utilities;

#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

.container {
  @apply max-w-screen-xl mx-auto;
}

body {
  background: #eee;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  /* margin: 100px auto; */
  width: 320px;
  height: 240px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

:root {
  --posters-slider-shadow-bg-color: #000;
  --posters-slider-width: 933vw;
  --posters-slider-height: 933vw;
}

@media not all and (min-width: 1024px) {
  :root {
    --posters-slider-shadow-bg-color: #000;
    --posters-slider-width: 32vw;
    --posters-slider-height: 32vw
  }

  .posters-slider {
    --posters-slider-width: 80vw;
    --posters-slider-height: 80vw
  }
}

@media not all and (max-width: 1023px) {
  .posters-slider {
    --posters-slider-width: 32vw;
    --posters-slider-height: 32vw
  }
}

.posters-slider {
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.posters-slider .swiper {
  overflow: visible;
  width: var(--posters-slider-width);
  height: var(--posters-slider-height);
  max-width: 550px;
  max-height: 550px;
}

.posters-slider .swiper-slide {
  border-radius: 10px;
  justify-content: center;
  display: flex
}

.posters-slider .swiper-slide-shadow {
  /* background: var(--posters-slider-shadow-bg-color); */
}

.posters-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover
}

.text-header {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3.625rem;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.text-banner {
  color: #FFF;
  text-shadow: 5px 5px 6px rgba(0, 0, 0, 0.25);
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 126%;
}

h1 {
  color: #000;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.625rem;
}

.background-button {
  background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
}

.background-button:hover {
  background: #0156B3;
}